.loginTextInput {
    margin-bottom: 10px!important;
}

.loginContainer {
    display:flex;
    flex-direction: column;
}

.loginContainer {
    display:flex;
    flex-direction: column;
}
.loginTextInput {
    margin-bottom: 10px!important;
}
.errorMessage{
    color: red;
    font-weight: bolder;
}
